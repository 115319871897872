<template>
    <div class="finish-wrap">
        <div class="logo">
            <img src="../assets/images/bentley_logo_a-c1@2x.png"  alt="" />
        </div>
        <div class="info-box">
            <div class="box-top">
                <div class="left">
                   <span>{{userInfo.surname}}{{userInfo.firstName}}</span>
                    <strong>{{userInfo.hubId}}</strong>
                </div>
                <div class="right">{{userInfo.jobRole}}</div>
            </div>
            <div v-if="needDC()" class="box-bottom">
                {{ (dcOk) ? dateText + '日完成DC认证' : '未DC认证' }} 
            </div>
        </div>
        <div class="video-box" @click="trainingImgClick()">
            <img :src="`${aliyunOssUrl}/base/training/learning_banner.jpg`"  alt="" />
        </div>
        <div v-show="!dcOk" class="time-box">
            <span>DC认证</span>
            <strong>{{dateText}} {{timeInfo.appointmentTime && timeInfo.appointmentTime.replace('-', '至')}}</strong>
            <div :class="['cancel-btn', {'disabled': false}]" @click="handleCancel">取消预约</div>
        </div>
        <div v-if="needDC()" class="text-bottom">
          <span>*DC：发展评估中心</span>
        </div>
    </div>
</template>
<script>
import {Landing} from '@/network'
import { Toast, Dialog } from 'vant';
import moment from 'moment';

export default {
    data() {
        return {
            hubId: sessionStorage.getItem('hubId') || null,
            phone: sessionStorage.getItem('phone') || null,
            userInfo: {},
            timeInfo: {},
            version: null,
            allowDC: null,
            enrollStartDate: null,
            enrollEndDate: null,
            aliyunOssUrl: process.env.VUE_APP_ALIYUN_OSS_URL
        }
    },
    computed: {
        dateText() {
            if (!this.timeInfo || !this.timeInfo.appointmentDate) return
            return moment(this.timeInfo.appointmentDate).format('M月D日')
        },
        dcOk() {
          return this.timeInfo && this.timeInfo.appointmentDate && this.getDaysBetween2(this.timeInfo.appointmentDate) >= 3
        }
    },
    async created() {
        this.userInfo = await Landing.getUserInfo({phone: this.phone})
        this.timeInfo = await Landing.getReservation({phone: this.phone})
        // const {data} = await Landing.getValidDate({pageNum:1, pageSize:9999, jobRole: this.userInfo.jobRole, 
        // appointmentDate:this.timeInfo.appointmentDate, appointmentNo: this.timeInfo.appointmentNo})
        const {data} = await Landing.getValidDate({pageNum:1, pageSize:9999, searchCondition: 'phone',
            appointmentDate:this.timeInfo.appointmentDate, appointmentNo: this.timeInfo.appointmentNo})
        if (data) {
          this.version = data[0].version
        }
        this.allowDC = await Landing.getConfig({paramKey: 'allow_dc'})
        this.enrollStartDate = await Landing.getConfig({paramKey: 'dc_enroll_start_date'})
        this.enrollEndDate = await Landing.getConfig({paramKey: 'dc_enroll_end_date'})
    },
    methods: {
        needDC() {
            // return (this.userInfo.jobRole == '销售经理' || this.userInfo.jobRole == '售后服务经理') ? true : false
            if (this.allowDC && this.allowDC.indexOf(this.hubId) >= 0) {
                return true
            }
            return false
        },
        canAppoiintment(startDateStr, endDateStr) {
            let res = false
            if (startDateStr && endDateStr) {
                let startDate = new Date(startDateStr)
                let endDate = new Date(endDateStr)
                let now = new Date()
                if (now >= startDate && now < endDate) {
                    res = true
                }
            } else {
                res = true
            }
            console.log('xxxxxxxxxx res= ', res)
            return res
        },
        handleCancel() {
            // if (this.getDaysBetween(this.timeInfo.appointmentDate) < 5) {
            //   Dialog.alert({
            //     width: '96%',
            //     allowHtml: true,
            //     className: 'dialog-alert-font-class2',
            //     messageAlign: 'left',
            //     message: '很抱歉，距离DC开始仅5个工作日<br>如您需取消预约，请发送邮件至BMC培训助理<br>BMC_trainingassistant@gpstrategies.com<br>并抄送BMC培训学院<br>bentleyacademy@bentleymotorschina.com'
            //   })
            if (!this.canAppoiintment(this.enrollStartDate, this.enrollEndDate)) {
                Dialog.alert({
                    width: '95%',
                    allowHtml: true,
                    className: 'dialog-alert-font-class2',
                    messageAlign: 'left',
                    title: '非常抱歉',
                    message: '本次预约已经截止，您将无法取消该预约。如有任何问题，请您与培训助理进行联系：<br>Gillian Kong<br>BMC_trainingassistant@gpstrategies.com'
                })
            } else {
                Dialog.confirm({
                    width: '300px',
                    allowHtml: true,
                    className: 'dialog-alert-font-class-course-detail',
                    messageAlign: 'center',
                    title: '取消预约',
                    message: '确定取消预约?'
                }).then(() => {
                    Landing.deleteReservation({hubId: this.hubId, version: this.version, area: 'cn'}).then(() => {
                        Toast('取消预约成功')
                        this.$router.replace({name: 'AfterForm'})
                    })
                }).catch(() => {
                });
              
            }
        },
        getDaysBetween(dateString){
          var  endDate = Date.parse(dateString);
          var days=(endDate - new Date())/(1*24*60*60*1000);
          // alert(days);
          return  days;
        },
        getDaysBetween2(dateString){
          var  endDate = Date.parse(dateString);
          var days=(new Date() - endDate)/(1*24*60*60*1000);
          return  days;
        },
        trainingImgClick() {
            this.$router.push({name: 'TrainingList'})
        },
    }
}
</script>

<style lang="less">
.dialog-alert-font-class2 {
  font-family: 'Bentley', 'simhei', RegularEng, Helvetica, 'Avenir', Arial, sans-serif !important;
  font-size: 12px !important;
  @dialog-width: 400px;
  @dialog-font-size: @font-size-sm;
}
</style>


<style lang="scss" scoped>
.logo {
    width: 375px;
    height: 80px;
    background-color: white;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    img {
        width: 84px;
        height: 42px;
        margin: 20px 20px;
    }
}
.info-box {
    width: 100%;
    height: 125px;
    box-sizing: border-box;
    padding: 0 25px;
    background-color: rgba(0,50,32,0.7);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    .box-top {
        width: 100%;
        height: 70px;
        box-sizing: border-box;
        padding-bottom: 8px;
        border-bottom: 2px solid #fff;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 12px;
        .left {
            width: auto;
            height: auto;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            span {
                font-size: 16px;
                margin-right: 20px;
            }
            strong {
                font-weight: 400;
                font-size: 12px;
            }
        }
        .right {
            font-size: 16px;
        }
    }
    .box-bottom {
        width: 100%;
        height: auto;
        color: #fff;
        font-size: 16px;
    }
}
.video-box {
    display: flex;
    justify-content: center;
    padding: 10px 0px 10px;
    img {
        width: 100%;
        max-height: 160px;
        // border-radius: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
.time-box {
    width: 335px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding-top: 18px;
    border: 1px solid rgba(227, 227, 227, 1);
    border-radius: 6px;
    background-color: #fff;
    margin: 0 auto;
    span {
        font-size: 16px;
        color: #000;
        margin-bottom: 15px;
    }
    strong {
        font-size: 16px;
        color: rgba(128, 128, 128, 1);
        font-weight: 400;
        margin-bottom: 20px;
    }
    .cancel-btn {
        width: 125px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(200, 200, 200, 1);
        border-radius: 6px;
        font-size: 16px;
        color: #333;
        &.disabled {
            color: #fff;
            background: #999;
            border: 0;
        }
    }
}
.finish-wrap{
  width: 100%;
  height: 100vh;
}
.text-bottom {
  margin: 35px auto auto 20px;
//   position: fixed;  
  font-size: 12px;
}
</style>